import { styled } from 'dripsy';
import { AnimatePresence, MotiView as NativeMotiView } from 'moti';
import useDrawerStore from '@src/stores/drawerStore';
import { Ionicons, Pressable, Text, View } from '@ui/atoms';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import useDeviceDimensions from '@hooks/useDeviceDimensions';
import { useMemo } from 'react';
import useAuthStore, { logout } from '@src/stores/authStore';
import useUserResidencesQuery from '@hooks/queries/useUserResidencesQuery';
import SelectResidenceDialog from '@ui/organisms/SelectResidenceDialog';
import SCREENS from '@constants/Screens';
import { Alert, Platform } from 'react-native';
import useDialog from '@hooks/useDialog';
import useWebView from '@hooks/useWebView';
import useTranslate from '@hooks/useTranslate';


const MotiView = styled(NativeMotiView)();

function Drawer() {
  const { isOpen, close } = useDrawerStore();
  const { navigate } = useNavigation();
  const insets = useSafeAreaInsets();
  const dimensions = useDeviceDimensions();
  const { open } = useDialog();
  const openWebView = useWebView();

  const { t } = useTranslate();

  const setResidenceId = useAuthStore((state) => state.setResidenceId);

  const { data: residences } = useUserResidencesQuery();

  const items = useMemo(() => {
    return [
      { label: 'ui.Drawer.surveys', handlePress: () => navigate(SCREENS.surveys), visible: 'web' === Platform.OS },
      { label: 'ui.Drawer.events', handlePress: () => navigate(SCREENS.events), visible: 'web' === Platform.OS },
      { label: 'ui.Drawer.documents', handlePress: () => navigate(SCREENS.documents) },
      { label: 'ui.Drawer.resources', handlePress: () => navigate(SCREENS.resources) },
      { label: 'ui.Drawer.neighbors', handlePress: () => navigate(SCREENS.neighbors) },
      { label: 'ui.Drawer.profile', handlePress: () => navigate(SCREENS.editProfile) },
      { label: 'ui.Drawer.inviteUser', handlePress: () => navigate(SCREENS.inviteUser) },
      {
        label: 'ui.Drawer.switch',
        handlePress: () => {
          if ('web' === Platform.OS) {
            open(SelectResidenceDialog);
          } else {
            setResidenceId(null);
          }
        },
        visible: 1 < residences.length,
      },
      {
        label: 'ui.Drawer.feedback.name',

        handlePress: () => openWebView(t('ui.Drawer.feedback.name'), t('ui.Drawer.feedback.url')),

      },
      { label: 'ui.Drawer.logout', handlePress: () => logout() },
    ].filter((item) => false !== item.visible);
  }, [navigate, open, residences.length, setResidenceId]);

  const handlePress = (item) => {
    item.handlePress();
    close();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <MotiView
          from={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'timing', duration: 200 }}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            height: dimensions.height,
            width: dimensions.width,
            bg: '$transparent.black.70',
            pt: insets.top,
            pb: insets.bottom,
            pl: insets.left,
            pr: insets.right,
          }}
        >
          <View sx={{ p: '$4' }}>
            <Pressable onPress={close} sx={{ position: 'absolute', right: '$4', top: '$4' }}>
              <Ionicons name="close-outline" size={40} sx={{ color: '$common.white' }} />
            </Pressable>
            <View
              sx={{
                mt: '$9',
                pr: '$5',
                alignItems: 'flex-end',
              }}
            >
              {items.map((item) => (
                <Pressable key={item.label} onPress={() => handlePress(item)} sx={{ mt: '$4' }}>
                  <Text sx={{ textAlign: 'right', fontSize: 24, color: '$common.white' }}>
                    <FormattedMessage id={item.label} />
                  </Text>
                </Pressable>
              ))}
            </View>
          </View>
        </MotiView>
      )}
    </AnimatePresence>
  );
}

export default Drawer;
